
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































.layout {
  @include mq($until: s) {
    gap: 4rem;
    flex-direction: column-reverse;
  }
}

.layout__aside__sidebar {
  @include mq($until: s) {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1rem 0 0.5rem;
    background-color: $c-white;
    border-top: 1px solid $elantis-grey-light;

    ::v-deep .sidebar__item {
      flex-direction: column;
      align-items: center;
      margin: 0;
      padding: 0 2.5rem;
    }

    ::v-deep .sidebar__item__icon {
      margin: 0;
    }

    ::v-deep .link {
      font-size: 0.9rem;
    }
  }
}

.layout__aside__faq {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  padding: 1.5rem;
  border: 1px solid $elantis-grey-light;
  border-radius: 0.8rem;

  @include mq(s) {
    max-width: 21rem;
    margin-top: 4rem;
    margin-bottom: 0;
  }
}

.layout__aside__faq__title {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: $elantis-blue;
  font-size: 1.8rem;
  font-weight: 700;
}

.layout__aside__faq__text {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 142.857%;
}
