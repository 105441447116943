
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































































































.sign__error {
  display: flex;
  align-items: center;
  color: $c-black;

  svg {
    width: 3.2rem;
    height: 3.2rem;
    margin-right: 1.5rem;
    fill: $elantis-orange;
  }
}

.sign__info {
  @include mq(s) {
    width: col(3, 14);
    margin-right: col(1, 14);
  }

  @include mq(m) {
    width: col(3, 12);
    margin-right: col(1, 12);
  }

  @include mq(xxl) {
    width: col(2, 10);
    margin-right: col(1, 10);
  }
}

.sign__info__title {
  @extend %fw-bold;

  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.sign__documents {
  width: 100%;

  @include mq(s) {
    width: col(10, 14);
  }

  @include mq(m) {
    width: col(8, 12);
  }

  @include mq(xxl) {
    width: col(7, 10);
  }
}

.sign__howto {
  display: block;
}
