
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































.document-list {
  border: solid $elantis-grey-light;
  border-width: 0.2rem 0 0;
}

.excerpt {
  max-width: col(11);

  @include mq(l) {
    max-width: col(9);
  }

  @include mq(xxl) {
    max-width: col(7, 10);
  }
}

.header {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
}

.header__icon {
  width: 3rem;
  height: 3rem;
  margin-right: 0.85rem;
  margin-bottom: 0.2rem;
  fill: $c-black;
}

.list {
  margin: 0;
  padding: 0;
}

.list__item {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 10px, xxl: 20px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 10px, xxl: 20px));

  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  border-top: solid 1px $elantis-grey-light;
}

.list__item__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 12px;

  @include mq(s) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    flex-basis: col(6, 10);
  }

  @include mq(m) {
    flex-basis: col(5, 8);
  }

  @include mq(xxl) {
    flex-basis: col(5, 7);
  }
}

.list__item__info__label {
  flex-shrink: 0;
  margin-top: 0.5rem;

  @include mq(s) {
    width: col(2, 6);
    margin-top: 0;
  }

  @include mq(m) {
    width: col(2, 5);
    margin-top: 0;
  }

  @include mq(xl) {
    width: col(1, 5);
  }
}

.list__item__cta {
  flex-shrink: 0;

  @include mq($until: xs) {
    ::v-deep .btn__label {
      display: none;
    }

    ::v-deep .btn__icon {
      margin-right: 0 !important;
    }
  }
}

.list__item__link {
  width: 2.4rem;
  height: 2.4rem;
}
