
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































































.sidebar__item {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  @include mq(s) {
    &.help {
      display: none;
    }
  }
}

.sidebar__item__icon {
  margin-right: 2.5rem;
  fill: $tundora;

  .is-active & {
    fill: $elantis-blue;
  }
}

.sidebar__item__links {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}

.link {
  @extend %fw-book;

  color: $tundora;
  font-size: 1.8rem;
  text-decoration: none;

  .is-active & {
    @extend %fw-bold;

    color: $elantis-blue;
  }
}

.link__label {
  @include mq($until: s) {
    text-transform: uppercase;
  }
}

.archived {
  display: block;
  color: $c-gray-darker;
  font-size: 1.4rem;
  text-decoration: none;

  sup {
    @extend %fw-bold;

    display: inline-block;
    margin-left: 0.5rem;
    color: $elantis-orange;
    font-size: 1.1rem;
  }
}
